import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './shared/components/main/main.component';
import { LoginGuard } from '@core/guards/login/login.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    component: MainComponent,
    canActivateChild: [LoginGuard],
    canActivate: [LoginGuard],
    children: [
      {
        path: 'gestaoAcesso',
        loadChildren: () =>
          import('./modules/gestaoAcesso/gestao-acesso.module').then(
            m => m.GestaoAcessoModule
          )
      },
      {
        path: 'agendamento',
        loadChildren: () =>
          import('./modules/agendamento/agendamento.module').then(
            m => m.AgendamentoModule
          )
      },
      {
        path: 'inventario',
        loadChildren: () =>
          import('./modules/inventario/inventario.module').then(
            m => m.InventarioModule
          )
      },
      {
        path: 'gestaoPatio',
        loadChildren: () =>
          import('./modules/gestaoPatio/gestao-patio.module').then(
            m => m.GestaoPatioModule
          )
      },
      {
        path: 'fluxoCarga',
        loadChildren: () =>
          import('./modules/fluxoCarga/fluxoCarga.module').then(
            m => m.FluxoCargaModule
          )
      },
      {
        path: 'relatorios',
        loadChildren: () =>
          import('./modules/relatorios/relatorios.module').then(
            m => m.RelatoriosModule
          )
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
